
<template>
  <div class="">
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{$trans('Payment')}}
      </span>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Keyword') }}</label>
            <input v-model="filterQuery.keyword" :placeholder="'keyword here!'" type="text" class="form-control">
          </div>
          <div class="form-group mr-3">
            <label>{{$trans('Status')}}</label>
            <select class="form-control" v-model="filterQuery.status">
              <option value="all">{{$trans('All')}}</option>
              <option v-for="(s,k) in order_status" :key="k" :value="k">{{$trans(s)}}</option>
            </select>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Group') }}</label>
            <Select2 class="w-200" v-model="filterQuery.groups" :options="project_groups" :settings="{  multiple:true}"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Partner') }}</label>
            <Select2 class="w-200" v-model="filterQuery.partner_id" :options="partners"/>
          </div>
        </div>
<!--        <div class="d-flex">
          <div class="form-group mr-3">
            <label class="d-block">{{ $trans('From date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
          </div>
          <div class="form-group  mr-3">
            <label class="d-block">{{ $trans('To date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
          </div>
        </div>-->
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="requests && requests.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{$trans('Results')}}: <strong>{{requests.data.length}}</strong> {{$trans('of')}} <strong>{{requests.total}}</strong></small>
          </div>
          <table class="table mb-3">
            <thead>
            <tr>
              <th>{{$trans('Ref Number')}}</th>
              <th>{{$trans('Date')}}</th>
              <th>{{$trans('Type')}}</th>
              <th>{{$trans('Project')}}</th>
              <th>{{$trans('Group')}}</th>
              <th>{{$trans('Partner')}}</th>
              <th>{{$trans('Bank Name')}}</th>
              <th>{{$trans('Bank Account Name')}}</th>
              <th>{{$trans('Bank Account Number')}}</th>
              <th class="text-right">{{$trans('Amount')}}</th>
              <th>{{$trans('Discount')}}</th>
              <th class="text-right">{{$trans('Total VND')}}</th>
              <th class="text-right">{{$trans('Total USD')}}</th>
              <th>{{$trans('Note')}}</th>
              <th>{{$trans('Status')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(request,index) in requests.data" v-bind:key="index">
              <td class="link" @click="detail(request)">{{request.ref_number}}</td>
              <td>{{request.finish_date}}</td>
              <td>{{$trans(request.type)}}</td>
              <td>
                <slot v-if="request.project">{{request.project.name}}</slot>
              </td>
              <td>
                <slot v-if="request.project">{{request.project.group}}</slot>
              </td>
              <td>
                <slot v-if="request.partner">{{ request.partner.name }}</slot>
              </td>
              <td>
                {{request.payment_info.bank_name}}
              </td>
              <td>
                {{request.payment_info.bank_account_name}}
              </td>
              <td>
                {{request.payment_info.bank_account_number}}
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    :value="request.amount"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="%"
                    :value="request.discount"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    :value="request.total"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    :value="request.total_usd"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td>{{request.note}}</td>
              <td>
                <request-status :status="request.status"></request-status>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="requests && requests.data && requests.last_page"
                    :page-count="requests.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="detailForm" v-bind:width="'100%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Request Detail')}}
          <button @click="detailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <div class="row">
          <div class="col-sm-7">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th class="nowrap">{{$trans('Ref Number')}}</th>
                <td colspan="2">{{request.ref_number}}</td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Date')}}</th>
                <td colspan="2">{{request.finish_date}}</td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Type')}}</th>
                <td colspan="2">{{$trans(request.type)}}</td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Project')}}</th>
                <td colspan="2">
                  <slot v-if="request.project">{{request.project.name}}</slot>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Partner')}}</th>
                <td colspan="2">
                  <slot v-if="request.partner">{{request.partner.name}}</slot>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Amount')}}</th>
                <td colspan="2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.amount"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Discount')}}</th>
                <td colspan="2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency="%"
                      v-model="request.discount"
                      v-bind:read-only="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Total amount')}}</th>
                <td  colspan="2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.total"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Bank Name')}}</th>
                <td  colspan="2">
                  {{request.payment_info.bank_name}}
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Bank Account Name')}}</th>
                <td  colspan="2">
                  {{request.payment_info.bank_account_name}}
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Bank Account Number')}}</th>
                <td  colspan="2">
                  {{request.payment_info.bank_account_number}}
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Status')}}</th>
                <td colspan="2">
                  <request-status :status="request.status"></request-status>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{$trans('Note')}}</th>
                <td class="pre-line" colspan="2">{{request.note}}</td>
              </tr>
              </tbody>
            </table>
            <h5 class="d-flex text-uppercase">
              <span class="mr-2">{{$trans('Detail')}}</span>
              <a v-if="$can('edit-detail-orders')" @click="editDetailForm = true" href="javascript:void(0)">
                <svg viewBox="0 0 24 24" width="17" height="17" stroke="currentColor" stroke-width="2" fill="none"
                     stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </a>
            </h5>
            <table class="table-striped table">
              <thead>
              <tr>
                <th>{{$detail_title_text(request.type)}} </th>
                <th>{{$detail_text_text(request.type)}}</th>
                <th>{{$trans('VND')}}</th>
                <th>{{$trans('USD')}}</th>
                <th>{{$trans('Report Link')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,k) in request.detail" :key="k">
                <td v-html="$short_link(item.title)"></td>
                <td v-html="$short_link(item.text)"></td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      :value="item.price"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      :value="item.usd"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
                <td v-html="$short_link(item.report_link)"></td>
              </tr>
              </tbody>
            </table>
            <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
              {{alert.message}}
            </div>
            <div class="form-group">
              <label>{{$trans('Proof of money transfer')}}</label>
              <input class="form-control mb-2" type="file" @change="uploadFile" ref="file">
            </div>
            <div v-if="$can('payment-orders')" class="form-actions">
              <div v-if="request && request.status == 4" class="d-flex">
                <button @click="confirm(request)" class="btn btn-primary mr-3">
                  <div v-if="process_change_status" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{$trans('Paid')}}
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div v-if="logs" class="card shadow-0 border" style="background-color: #f0f2f5;">
              <div class="card-body p-4">
                <div v-for="(log,k) in logs" :key="k" class="card mb-2">
                  <div class="card-body p-2">
                    <div class="mb-1">
                      {{log.title}}
                      <slot v-if="log.image">
                        <br>
                        <a target="_blank" :href="url+'/uploads/'+log.image">
                          <img :src="url+'/uploads/'+log.image" alt="">
                        </a>
                      </slot>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <img src="../assets/img/avatars/6.png" alt="" width="25" height="25"/>
                        <p class="small mb-0 ms-2"><strong>{{log.username}}</strong></p>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <p class="small text-muted mb-0">{{log.time}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </modal>
    <modal v-if="editDetailForm" v-bind:width="'1000px'" >
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Edit Detail')}}
          <button @click="editDetailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <div class="form-group">
          <label>{{$trans('Detail')}} (*)</label>
          <table class="table-striped table">
            <thead>
            <tr>
              <th>{{$detail_title_text(request.type)}} </th>
              <th>{{$detail_text_text(request.type)}}</th>
              <th>{{$trans('VND')}}</th>
              <th>{{$trans('USD')}}</th>
              <th>{{$trans('Report Link')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,k) in detail_table" :key="k">
              <td>
                <input v-model="detail_table[k]['title']" class="form-control" type="text">
              </td>
              <td>
                <input v-model="detail_table[k]['text']" class="form-control" type="text">
              </td>
              <td>
                <vue-numeric class="form-control"
                             currency-symbol-position="suffix"
                             currency=""
                             v-model="detail_table[k]['price']"
                             v-bind:read-only="false"
                             :minus="true"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                <vue-numeric class="form-control"
                             currency-symbol-position="suffix"
                             currency=""
                             v-model="detail_table[k]['usd']"
                             v-bind:read-only="false"
                             :minus="true"
                             v-bind:precision="2">
                </vue-numeric>
              </td>
              <td>
                <input v-model="detail_table[k]['report_link']" class="form-control" type="text">
              </td>
              <td class="text-right">
                <a @click="removeDetail(k)" href="javascript:void(0)">
                  {{ $trans('Remove') }}
                </a>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="4"></td>
              <td class="text-right">
                <a @click="addDetail()" href="javascript:void(0)">
                  {{ $trans('Add') }}
                </a>
              </td>
            </tr>
            </tfoot>
          </table>
          <slot v-if="errors && errors['detail']">
            <div class="text-danger" v-for="error in errors['detail']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update_detail" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{$trans('Update')}}
        </button>
      </slot>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Payment",
  data:function () {
    return {
      editDetailForm:false,
      process:false,
      process_change_status:false,
      formFilter:false,
      process_reject:false,
      editPriceForm:false,
      filterQuery:{
        page:1,
        from: null,
        to: new Date(),
        status:'all',
        partner_id:'',
        project_id:'',
        keyword:''
      },
      requests:null,
      process_loading:false,
      detailForm:false,
      partners_res:null,
      projects_res:null,
      request:null,
      alert:null,
      errors:{},
      Images:null,
      detail_table:[
        {
          title:'',
          text:'',
          price:0,
          usd:0,
          report_link:''
        }
      ],
    }
  },
  mounted() {
    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    if (month == 1) {
      month = 12;
      year = year - 1;
    }else {
      month = month -1
    }
    this.filterQuery.from = new Date(year, month, 1);
  },
  methods:{
    addDetail: function () {
      let item = {
        title: '',
        text: '',
        price: 0,
        usd: 0,
        report_link:''
      }

      let items = this.detail_table
      items.push(item)
      this.detail_table = items
    },
    removeDetail: function (index) {
      let items = this.detail_table
      items.splice(index, 1);

      this.detail_table = items
    },
    update_detail:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          _id:this.request._id,
          detail:this.detail_table,

        }
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/orders/update-detail/'+data._id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.editDetailForm = false;
                this.index();
                this.detail(this.request)
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index:function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/payments',params:this.filterQuery, method: 'GET'})
          .then(resp => {
            this.requests = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter:function (){
      var today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (month == 1) {
        month = 12;
        year = year - 1;
      }else {
        month = month -1
      }
      this.filterQuery = {
        from: new Date(year, month, 1),
        to: new Date(),
        page:1,
        status:'all',
        partner_id:'',
        project_id:'',
        keyword:'',
      }
      this.index()
    },
    detail:function (request){
      axios({url: this.$root.$data.api_url + '/api/payments/'+request._id, method: 'GET'})
          .then(resp => {
            this.request = resp.data.data;
            this.detailForm = true;
            this.errors ={}
            this.alert =null
            this.detail_table = resp.data.data.detail
          })
          .catch(err => {
            console.log(err)
          })
    },
    confirm:function (request){
      if( this.process_change_status == false){
        this.process_change_status = true;
        this.errors = {}
        this.alert = null
        let data = {
          _id:request._id,
        }
        axios({url: this.$root.$data.api_url + '/api/payments/confirm/'+data._id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.index();
                this.detail(this.request)
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_change_status = false;
            })
            .catch(err => {
              console.log(err)
              this.process_change_status = false;
            })
      }
    },
    uploadFile:function (){
      this.Images = this.$refs.file.files[0];
      this.submitFile()
    },
    submitFile:function () {
      const formData = new FormData();
      formData.append('file', this.Images);
      const headers = { 'Content-Type': 'multipart/form-data' };
      let data = {
          _id:this.request._id
      }
      axios.post(this.$root.$data.api_url + '/api/payments/upload-proof/'+data._id, formData, { headers }).then((resp) => {
        resp.data.files; // binary representation of the file
        resp.status; // HTTP status
        let error_code = resp.data.error_code;
        if( error_code == 0){
          this.Images = null;
          this.detail(this.request)
        }else{
          this.alert = {
            type: 'warning',
            message: resp.data.message
          }
          this.errors = resp.data.errors || {};
        }
        this.process = false;
      });
    },
    get_partners: function () {
      axios({url: this.$root.$data.api_url + '/api/user/partners', params: {}, method: 'GET'})
          .then(resp => {
            this.partners_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.index()
    this.get_partners()
  },
  computed:{
    seo_services:function (){
      return this.$root.$data.global_settings.seo_services
    },
    order_status:function (){
      return this.$root.$data.global_settings.order_status
    },
    projects:function (){
      if( !this.projects_res){
        return [];
      }
      let projects =[];
      let  projects_res = this.projects_res;
      projects_res.forEach((el)=>{
        let item ={
          id:el._id,
          text:el.name
        }
        projects.push(item)
      })

      return  projects;
    },
    partners:function (){
      if( !this.partners_res){
        return [];
      }
      let partners =[];
      let  partners_res = this.partners_res;
      partners_res.forEach((el)=>{
        let item ={
          id:el._id,
          text:el.name
        }
        partners.push(item)
      })

      return  partners;
    },
    user:function (){
      return this.$root.user
    },
    url:function (){
      return this.$root.$data.api_url
    },
    logs:function (){
      if( !this.request){
        return  null
      }
      if( !this.request.logs){
        return  null
      }
      let logs = this.request.logs
      return  logs.reverse()
    },
    project_groups:function (){
      return this.$root.$data.global_settings.project_groups
    }

  }
}
</script>

<style scoped>

</style>